<template>
    <div>

        <div class="results" v-if="results">

            <div class="loadingStats" v-if="percentage < 100">
                <div class="title">{{ percentage }}%</div>
                <div class="targetBar" style="width: 60%">
                    <div class="completionBar" :style="`width: ${percentage}%;`"></div>
                </div>
            </div>

            <div class="reportResults" v-else>

                <div class="contentHolder">
                    <div class="row" :key="key" v-for="(item, key) of results.list">
                        <div class="title">{{ item.user.name }}</div>
                        <div class="value">${{ item.report.total }}</div>
                    </div>
                </div>

                <div class="total">
                    Total: <strong>${{ priceConverter(results.total) }}</strong>
                </div>
            </div>

            <div class="_action" v-if="percentage >= 100">
                <button class="default medium reverse" @click="cancelReport">Cancel</button>
                <button class="default medium" @click="clearReport">Clear</button>
                <form :action="`${apiURL}/reports/FullReportPDF`" target="_blank" method="POST">
                    <input type="text" name="report" :value="JSON.stringify(results)" hidden>
                    <button class="default medium" type="submit">Download PDF</button>
                </form>
            </div>

        </div>
        <div class="loadingStats" v-else>
            <button class="default" @click="startFullReport">Generate Full Report</button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueEllipseProgress from 'vue-ellipse-progress';

    export default {
        props: ['report'],
        components: {
            VueEllipseProgress
        },
        data() {
            return {
                results: null
            }
        },
        computed: {
            ...mapGetters(['webSocket']),
            apiURL() {
                return process.env.VUE_APP_API_URL;
            },
            total() {
                let total = 0;
                for(const item of this.results.list) {
                    total += Number(item.report.total);
                }

                return total;
            },
            percentage() {
                if(!this.results) return 0;
                return (this.results.countDone * 100 / this.results.countAll).toFixed(0);
            }
        },
        methods: {
            clearReport() {
                this.results = null;
            },
            cancelReport() {
                this.results = null;
                this.$emit('cancel');
            },
            startFullReport() {
                this.webSocket.send('GenerateFullReport', {
                    report: this.report
                });
            },
            addWebSocketListeners() {
                if(!this.webSocket) return;
                this.webSocket.on('FullReportResults', (body) => {
                    this.results = body;
                });
            }
        },
        mounted() {
            this.addWebSocketListeners();
        },
        watch: {
            webSocket() {
                this.addWebSocketListeners();
            }
        }
    }
</script>

<style lang="scss" scoped>

.results {
    display: grid;
    row-gap: 20px;
}

.reportResults {
    display: grid;
    row-gap: 20px;
    .total {
        text-align: right;
        font-size: 20px;
    }
}

.loadingStats {
    padding: 50px;
    display: grid;
    justify-items: center;
    row-gap: 20px;
    .title {
        text-align: center;
        font-size: 40px;
        font-weight: bold;
        color: $buttonColor;
    }
}

.list {
    .reportItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}


._action {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
}
</style>