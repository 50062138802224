<template>
    <div>

        <div class="reportSettings" v-if="!report">
            <div class="settingsHolder">
                <div>Start date: <strong>{{ options.date }}</strong></div>
                <div>Duration: <strong>{{ options.period }} weeks</strong></div>
            </div>
            <button class="default medium" :disabled="isLoading(['GenerateFullReport'])" @click="generateFullReport">{{ !isLoading(['GenerateFullReport']) ? 'Start' : 'Working on it...' }}</button>
        </div>

        <div class="fullReportGrid" v-else>

            <div class="reportList">

                <div>
                    <div :key="key" v-for="(item, key) of report.list" class="reportItem">
                        <div class="name">{{ item.user.name }}</div>
                        <div class="value">${{ priceConverter(Number(item.report.total).toFixed(2)) }}</div>
                    </div>
                </div>

                <div class="total">Total: <strong>${{ priceConverter(Number(report.total).toFixed(2)) }}</strong></div>
            </div>

            <div class="closePeriodGroup">
                <h2>Close selected period</h2>
                <p>Closing selected period will save all users salary settings (dispatch percentage, bonuses & carriers custom percentage) for payroll period of 4 weeks. </p>
                <div class="confirmAction">
                    <input type="checkbox" v-model="confirmClosing">
                    <span>I confirm to close selected payroll period.</span>
                </div>
                <button class="default medium" :disabled="!confirmClosing || isLoading(['ClosePayrollPeriod'])" @click="closePayrollPeriod">{{ isLoading(['ClosePayrollPeriod']) ? 'Closing...' : 'Close' }}</button>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: ['options'],
        data() {
            return {
                report: null,
                confirmClosing: false
            }
        },
        methods: {
            generateFullReport() {
                this.ajax('GenerateFullReport', {
                    url: '/reports/GetFullReport',
                    method: 'POST',
                    data: this.options
                }, (err, body) => {
                    if(err) {
                        alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.report = body;
                });
            },
            closePayrollPeriod() {

                this.ajax('ClosePayrollPeriod', {
                    url: '/reports/ClosePayrollPeriod',
                    method: 'POST',
                    data: this.options
                }, (err, body) => {
                    if(err) {
                        alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    alert("Payroll period settings have been saved.");
                });

            }
        },
        
    }
</script>

<style lang="scss" scoped>

.confirmAction {
    display: grid;
    grid-template-columns: auto auto;
    justify-items: start;
    justify-content: start;
    column-gap: 5px;
}

.reportList {
    display: grid;
    row-gap: 20px;
    .total {
        text-align: center;
        font-size: 18px;
    }
}

.fullReportGrid {
    display: grid;
    row-gap: 20px;
}

.closePeriodGroup {
    background: $bgSecondary;
    padding: 20px;
    display: grid;
    row-gap: 10px;
    box-shadow: $boxShadow;
    border-radius: $borderRadius;
    p {
        font-size: 12px;
        opacity: 0.75;
    }
}

.reportItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background: $bgSecondary;
    padding: 0 15px;
    border-bottom: 1px solid $borderColor;
    transition: ease 0.3s;
    &:first-child {
        border-radius: $borderRadius $borderRadius 0 0;
    }
    &:last-child {
        border-radius: 0 0 $borderRadius $borderRadius;
        border: 0;
    }
    &:hover {
        transform: scale(1.01);
        background: $buttonColor2;
        color: $buttonColor;
    }
    .name {

    }
    .value {
        font-size: 12px;
        font-weight: bold;
    }
}

.reportSettings {
    display: grid;
    justify-content: center;
    justify-items: center;
    align-items: center;
    row-gap: 20px;
}

.settingsHolder {
    display: grid;
    justify-content: center;
    justify-items: center;
    align-items: center;
}

.actionButtons {
    display: grid;
    justify-items: center;
    justify-content: center;
}
</style>