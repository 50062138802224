<template>
    <div class="reportItem">
        <div class="header" @click="showDetails = !showDetails">
            <div class="name">{{ item.name }} <span class="currentWeekLabel" v-if="currentYearWeek == name">Current</span></div>
            <div class="itemDetails">
                <div class="item gross" v-tooltip="'Gross Income'">${{ priceConverter(item.gross) }}</div>
                <div class="item income" v-if="item.wage > 0" v-tooltip="'Personal Income'">+${{ priceConverter(item.wage) }}</div>
                <div class="item" v-if="item.bonus > 0"  v-tooltip="'Bonus'">+${{ priceConverter(item.bonus) }}</div>
            </div>
            <!-- <div class="gross">${{ priceConverter(item.gross) }}</div> -->
        </div>
        <div class="details" v-if="showDetails">

            <div class="_stats">
                <div class="statsGroup">
                    <div class="_title"><i class="fas fa-dollar-sign"></i><span>Gross income</span></div>
                    <div class="_value">${{ priceConverter(item.gross) }}</div>
                </div>

                <div class="statsGroup">
                    <div class="_title"><i class="fas fa-boxes"></i><span>Number of Loads</span></div>
                    <div class="_value">{{ item.loads.length }}</div>
                </div>



                <ModalSimple v-model="showBonusDetails" title="Weekly targets" confirm="Understood" @confirm="() => { showBonusDetails = false; }">
                    <div class="bonusTargets">
                        <div class="statsGroup" :key="key" v-for="(target, key) of item.weeklyTarget">
                            <div class="_title"><i class="fas fa-flag"></i><span>Target {{ key + 1 }}</span></div>

                            <div class="_value _small">

                                    <div class="targetGroup">
                                        <div class="targetInfo">
                                            <div class="gross">{{ grossToTarget(target.target) }}%</div>
                                            <div class="target">${{ priceConverter(target.target) }}</div>
                                        </div>
                                        <div class="targetBar">
                                            <div class="completionBar" :style="`width: ${ grossToTarget(target.target) }%`"></div>
                                        </div>
                                    </div>
                                    
                                    <div class="_subtleVal" v-if="grossToTarget(target.target) < 100">${{ priceConverter((target.target - item.gross).toFixed(0)) }} left to earn an extra ${{ priceConverter(target.bonus) }}</div>
                                    <div class="_subtleVal" v-else>Congrats! You smashed it and earned ${{ priceConverter(target.bonus) }}</div>
                                
                            </div>
                        </div>
                    </div>
                </ModalSimple>




                <div class="statsGroup">
                    <div class="flexTitle">
                        <div class="_title"><i class="fas fa-dollar-sign"></i><span>Bonus earned</span></div>
                        <button class="default small reverse actionBtn" @click="showBonusDetails = true;"><i class="fas fa-question"></i></button>
                    </div>
                    <div class="_value">${{ priceConverter(item.bonus) }}</div>
                </div>

                

                <div class="statsGroup">
                    <div class="_title"><i class="fas fa-dollar-sign"></i><span>Personal income</span></div>
                    <div class="_value">${{ priceConverter((item.wage + item.bonus)) }}</div>
                </div>

            </div>

            <div class="loadList" v-if="item.loads.length > 0">
                <div class="load titleRow">
                    <div class="id">Load #</div>
                    <div class="carrier">Carrier</div>
                    <div class="origin">Origin</div>
                    <div class="destination">Destination</div>
                    <div class="dispatcher">Dispatcher</div>
                    <div class="rate">Rate</div>
                    <div class="income">Income</div>
                </div>

                <div class="load" :key="key" v-for="(load, key) of item.loads">
                    <div class="id">{{ load.loadID }}</div>
                    <div class="carrier">{{ load.carrierName }}</div>
                    <div class="origin">{{ load.origin }}</div>
                    <div class="destination">{{ load.destination }}</div>
                    <div class="dispatcher"><span class="linked">{{ userName(load.dispatcher) }}</span> <span v-if="load.sharingWith">with <span class="linked">{{ userName(load.sharingWith) }}</span></span></div>
                    <div class="rate">${{ priceConverter(load.rate) }}</div>
                    <div class="income"><span>+${{ priceConverter(load.wage) }}</span></div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
    export default {
        props: ['item', 'name', 'target', 'bonus'],
        data() {
            return {
                showDetails: false,
                showBonusDetails: false
            }
        },
        methods: {
            hideDetails() {
                this.showDetails = false;
            },
            userName(id) {
                for(const user of this.users) {
                    if(user._id == id) return user.name;
                }
                return 'Unknown'
            },
            grossToTarget(target) {
                return ((Number(this.item.gross) * 100) / Number(target)).toFixed(2);
            }
        },
        computed: {
            ...mapGetters(['users']),
            currentYearWeek() {
                let date = moment();
                return date.year() + "W" + date.isoWeek();
            }
        }
    }
</script>

<style lang="scss" scoped>
.reportItem {
    background: $bgSecondary;
    border-radius: $borderRadius;
    box-shadow: $boxShadow;
    border: 1px solid $borderColor;
    .header {
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        cursor: pointer;
        user-select: none;
        .name {
            font-weight: bold;
            font-size: 18px;
        }
        .gross {
            color: $buttonColor;
        }
    }
}

.details {
    padding: 20px;
    display: grid;
    row-gap: 20px;
    border-top: 1px solid $borderColor;
    ._stats {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        column-gap: 20px;
    }
}



.targetSet {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}


.currentWeekLabel {
    background: $buttonColor;
    color: $buttonColor2;
    font-size: 13px;
    font-weight: 400;
    padding: 3px 6px;
    border-radius: 5px;
    margin-left: 10px;
}

.loadList {
    background: $bgSecondary;
    border-radius: $borderRadius;
    overflow: hidden;
    border: 1px solid $borderColor;
    .load {
        display: grid;
        grid-template-columns: 100px minmax(0, 1fr) 250px 250px minmax(0, 1fr) 100px 100px;
        height: 50px;
        align-items: center;
        padding: 0 20px;
        &:nth-child(even) {
            background: $bg;
        }
        &.titleRow {
            font-weight: bold;
            border-bottom: 1px solid $borderColor;
        }
        .dispatcher {
            span.linked {
                color: $buttonColor;
            }
        }
        .income {
            span {
                color: $success;
                font-weight: bold;
            }
        }
    }
}

.itemDetails {
    display: flex;
    align-items: center;
    column-gap: 20px;
    .item {
        box-shadow: $boxShadow;
        border: 1px solid $borderColor;
        padding: 3px 10px;
        border-radius: $borderRadius;
        &.gross {
            background: $buttonColor;
            color: $buttonText;
        }
        &.income {
            background: $success;
            color: $buttonText;
        }
    }
}



.bonusTargets {
    display: grid;
    row-gap: 20px;
    .statsGroup {
        background: $bg;
        border: 1px solid $borderColor;
    }   
}
</style>