<template>
    <div class="itemHolder">

        <div class="row triple" :key="key" v-for="(item, key) of targets">
            <div class="title">${{ priceConverter(item.target) }}</div>
            <div class="value">${{ item.bonus }}</div>
            <div class="removeBtn"><button class="remove" @click="remove(key)"><i class="fas fa-trash"></i></button></div>
        </div>

        <form action="" @submit.prevent="addNewTarget" class="newTargetRow">
            <input class="invisibleInput" type="text" v-model="newTarget.target" placeholder="Target">
            <input class="invisibleInput" type="text" v-model="newTarget.bonus" placeholder="Bonus">
            <button type="submit" class="default small addBtn" :disabled="!isValidTarget" @click="addNewTarget"><i class="fas fa-plus"></i></button>
        </form>

    </div>
</template>

<script>
    export default {
        props: ['modelValue'],
        data() {
            return {
                targets: this.modelValue || [],
                newTarget: {
                    target: '',
                    bonus: ''
                }
            }
        },
        methods: {
            addNewTarget() {
                this.targets.push({
                    target: Number(this.newTarget.target),
                    bonus: Number(this.newTarget.bonus),
                });
                this.newTarget = {
                    target: '',
                    bonus: ''
                }
                this.targets.sort((a,b) => (a.target > b.target) ? 1 : ((b.target > a.target) ? -1 : 0));
            },
            remove(index) {
                this.targets.splice(index, 1);
            }
        },
        computed: {
            isValidTarget() {
                let pattern = /^\d+$/;
                return pattern.test(this.newTarget.target) && pattern.test(this.newTarget.bonus);
            }
        },
        watch: {
            modelValue() {
                this.targets = this.modelValue;
            },
            targets() {
                this.$emit('update:modelValue', this.targets);
            }
        }
    }
</script>

<style lang="scss" scoped>

.itemHolder {
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    background: $bgSecondary;
}


.newTargetRow {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) 40px;
    grid-template-rows: 60px;
    align-items: center;
    padding: 0 20px;
    border-top: 1px solid $borderColor;
    &:first-child {
        border: 0;
    }
    .addBtn {
        height: 40px;
        width: 40px;
        padding: 0;
    }
}

.row {
    display: grid;
    height: 50px;
    align-items: center;
    padding: 0 20px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;

    &.triple {
        grid-template-columns: repeat(2, minmax(0, 1fr)) 20px;
    }

    &:nth-child(2n) {
        background: $bg;
    }
    .title {
        font-weight: bold;
    }
    .value {
        text-align: right;
    }
    .removeBtn {
        width: 20px;
        height: 20px;
        .remove {
            width: inherit;
            height: inherit;
            background: transparent;
            border: 0;
            font-size: 10px;
            color: $error;
        }
    }
}
</style>