<template>
    <div class="settings">
        
        <div class="settingsGroup">
            <h2>Weekly target</h2>
            <Targets v-model="settings.weeklyTarget"/>
        </div>

        <div class="settingsGroup">
            <h2>Montly targets</h2>
            <Targets v-model="settings.monthlyTarget"/>
        </div>

        <button class="default" @click="saveChanges">Save</button>
        
    </div>
</template>

<script>
import Targets from './Targets.vue'
    export default {
        components: {
            Targets
        },
        data() {
            return {
                errors: {},
                settings: {
                    weeklyTarget: [],
                    monthlyTarget: []
                }
            }
        },
        methods: {
            getTargets() {
                this.$axios.get('/reports/targets')
                .then(data => {
                    this.settings = data.data;
                })
                .catch(err => {
                    alert("Couldn't get targets");
                });
            },
            saveChanges() {
                this.$axios.post('/reports/targets', this.settings)
                .then(data => {
                    this.$notify({
                        type: 'info',
                        text: 'Targets have been updated successfully'
                    });
                })
                .catch(err => {
                    alert("Something went wrong or invalid values are entered.");
                });
            }
        },
        mounted() {
            this.getTargets();
        }
    }
</script>

<style lang="scss" scoped>

.settings {
    display: grid;
    row-gap: 30px;
    padding: 30px 0 0 0;
}


.settingsGroup {
    display: grid;
    row-gap: 20px;
}

</style>