<template>
    <div class="dispatchSelector">
        
        <div class="searchDropDown" v-click-outside="hideDropDown">
            <div class="selected option leftBtn" @click="switchSelecting">
                <button>{{ selectedTeamUser }}</button>
                <button class="dropDownBtn"><i class="fas fa-caret-down"></i></button>
            </div>
            <div class="itemsDropDownGroup" v-show="isSelecting">
                <input type="text" class="searchItem" v-model="search" placeholder="Search">
                <div class="listOfItems">
                    <div class="_item" :key="key" v-for="(item, key) of searchedItems" @click="selectedDispatch = item._id; hideDropDown()" :class="{ 'active' : item._id == selectedDispatch }">
                        <span>{{ item.name }}</span>
                        <span class="check" v-if="item._id == selectedDispatch"><i class="fas fa-check"></i></span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
    export default {
        props: ['loads', 'modelValue'],
        data() {
            return {
                selectedDispatch: this.modelValue,
                isSelecting: false,
                search: ''
            }
        },
        computed: {
            ...mapGetters(['users', 'user']),
            selectedDispatchOption() {
                return this.selectedDispatch;
            },
            searchedItems() {
                return this.users.filter(item => {
                    let name = item.name.toLowerCase();
                    let search = this.search.toLowerCase();
                    return name.includes(search);
                });
            },
            selectedTeamUser() {
                for(const item of this.users) {
                    if(item._id == this.selectedDispatch) return item.name;
                }
                return 'Unknown'
            }
        },
        methods: {
            switchSelecting() {
                this.isSelecting = !this.isSelecting;
            },
            hideDropDown() {
                this.search = '';
                this.isSelecting = false;
            }
        },
        mounted () {
            this.$emit('update:modelValue', this.selectedDispatchOption);
        },
        watch: {
            modelValue() {
                this.selectedDispatch = this.modelValue;
            },
            selectedDispatchOption() {
                this.$emit('update:modelValue', this.selectedDispatchOption);
            }
        }
    }
</script>

<style lang="scss" scoped>
.dispatchSelector {
    .option {
        width: 200px;
        height: 50px;
        border: 0;
        background: transparent;
        overflow: hidden;
        border-radius: $borderRadius;
        color: $buttonColor;
        font-weight: bold;
        transition: ease 0.5s;
        &:hover {
            background: $buttonColor2;
        }
        button {
            border: 0;
            background: transparent;
            padding: 0 20px;
            width: 100%;
            height: 100%;
            color: inherit;
            font-weight: inherit;
            text-align: left;
        }
        .dropDownBtn {
            padding: 0;
            height: 30px;
            background: $buttonColor2;
            border-radius: 3px;
            text-align: center;
        }
    }
}

.searchDropDown {
    position: relative;
    z-index: 100;
    .selected {
        display: grid;
        grid-template-columns: minmax(0,1fr) 30px;
        padding-right: 10px;
        align-items: center;
    }
}

.itemsDropDownGroup {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    transform: translateY(20px);
    background: $bgSecondary;
    border-radius: $borderRadius;
    box-shadow: $boxShadow;
    display: grid;
    grid-template-rows: 44px auto;
    border: 1px solid $borderColor;
    overflow: hidden;

    .searchItem {
        border: 0;
        border-bottom: 1px solid $borderColor;
        padding: 0 20px;
        width: 100%;
    }
    .listOfItems {
        max-height: 300px;
        overflow-y: auto;
        width: 100%;
        ._item {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            &.active, &:hover {
                cursor: pointer;
                background: $bg;
                color: $buttonColor;
            }
        }
    }
}
</style>