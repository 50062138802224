<template>
    <div>

        <ModalSimple size="large" :hideButtons="true" title="Generate full report" v-model="generateFullReport">
            <FullReport :report="reportBody" @cancel="() => { generateFullReport = false }"/>
        </ModalSimple>

        <PageHeader title="Payroll">
            <div class="actionBar" v-if="isUserPermitted('reports', 'viewAll')">
                <button class="default simple _icon" @click="openReportModal">Generate report<i class="fas fa-dollar-sign"></i></button>
                <UserSelector v-model="selectedUser"/>
                <button class="default simple _icon" @click="showReportSettings = true">Settings<i class="fas fa-sliders-h"></i></button>
            </div>
        </PageHeader>

        <Modal v-model="showReportSettings" title="Report settings" v-if="isUserPermitted('reports', 'viewAll')">
            <ReportSettings/>
        </Modal>
        
       
        <div class="wrapper reportGroup">

            
            <h2>Report's Period</h2>
            <div class="periods">
                <button class="default small round" :key="key" v-for="(period, key) of periods" @click="selectedPeriod = key" :class="{ 'reverse' : key != selectedPeriod }">{{ period.name }}</button>
            </div>
            <PayRollSelector :period="periods[selectedPeriod].value" v-model:date="payRollDate"/>


            <div class="reportGroup" v-if="userSalarySettings">
                <h2>Salary Settings</h2>
                <div class="userSalarySettingsGrid">

                    <div class="settingsBlock">
                        <div class="title">Main settings</div>
                        <div class="content">

                            <div class="settingsRow" v-if="userSalarySettings.dispatchPercentage">
                                <div class="name">Dispatch Percentage</div>
                                <div class="value">{{ priceConverter(Number(userSalarySettings.dispatchPercentage).toFixed(2)) }}%</div>
                            </div>

                            <div class="settingsRow" v-if="userSalarySettings.salary">
                                <div class="name">Basic salary</div>
                                <div class="value">${{ priceConverter(Number(userSalarySettings.salary).toFixed(2)) }}</div>
                            </div>

                            <div class="settingsRow" v-if="userSalarySettings.teamBonus">
                                <div class="name">Team Bonus</div>
                                <div class="value">${{ priceConverter(Number(userSalarySettings.teamBonus).toFixed(2)) }}</div>
                            </div>

                            <div class="settingsRow">
                                <div class="name">Weekly bonus</div>
                                <div class="value">{{ userSalarySettings.disableWeeklyBonus ? 'Disabled' : 'Enabled' }}</div>
                            </div>

                            <div class="settingsRow">
                                <div class="name">Monthly bonus</div>
                                <div class="value">{{ userSalarySettings.disableMonthlyBonus ? 'Disabled' : 'Enabled' }}</div>
                            </div>

                        </div>
                    </div>

                    <div class="settingsBlock" v-if="userSalarySettings.customCarriers && userSalarySettings.customCarriers.length > 0">
                        <div class="title">Carrier's custom percentage</div>
                        <div class="content">
                            <div class="settingsRow" :key="key" v-for="(item, key) of userSalarySettings.customCarriers">
                                <div class="name">{{ carriersObj[item.carrier] ? carriersObj[item.carrier].name : 'Unknown carrier' }}</div>
                                <div class="value">{{ priceConverter(Number(item.percent).toFixed(2)) }}%</div>
                            </div>
                        </div>
                    </div>

                    <!-- bonuses settings -->
                    <div class="settingsBlock" v-if="userSalarySettings.dispatchTargets">
                        <div>
                            <div class="title">Weekly bonuses</div>
                            <div class="content">

                                <div class="settingsRow header">
                                    <div class="name">Target</div>
                                    <div class="value">Bonus</div>
                                </div>

                                <div class="settingsRow" :key="key" v-for="(item, key) of userSalarySettings.dispatchTargets.weeklyTarget">
                                    <div class="name">${{ priceConverter(Number(item.target).toFixed(2)) }}</div>
                                    <div class="value">${{ priceConverter(Number(item.bonus).toFixed(2)) }}</div>
                                </div>

                            </div>
                        </div>

                        <div>
                            <div class="title">Monthly bonuses</div>
                            <div class="content">

                                <div class="settingsRow header">
                                    <div class="name">Target</div>
                                    <div class="value">Bonus</div>
                                </div>

                                <div class="settingsRow" :key="key" v-for="(item, key) of userSalarySettings.dispatchTargets.monthlyTarget">
                                    <div class="name">${{ priceConverter(Number(item.target).toFixed(2)) }}</div>
                                    <div class="value">${{ priceConverter(Number(item.bonus).toFixed(2)) }}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- bonuses settings -->

                </div>
            </div>

            <div class="reportGroup" v-if="report">

                <ModalSimple v-model="incomeDetails" title="Income details" confirm="Understood" @confirm="hideIncomeDetails" closeText="Close">
                    <div class="incomeDetails">

                        <div class="incomeListDetails">
                            <div class="row" :key="key" v-for="(row, key) of report.messages">
                                <div class="text">{{ row.message }}</div>
                                <div class="value">${{ row.value }}</div>
                            </div>
                        </div>

                        <div class="totalRow">Total: <strong>${{ priceConverter(report.total) }}</strong></div>
                    </div>
                </ModalSimple>

                <ModalSimple v-if="report.monthlyTarget" v-model="showBonusDetails" title="Monthly targets" confirm="Understood" @confirm="() => { showBonusDetails = false; }">
                    <div class="bonusTargets">
                        <div class="statsGroup" :key="key" v-for="(target, key) of report.monthlyTarget">
                            <div class="_title"><i class="fas fa-flag"></i><span>Target {{ key + 1 }}</span></div>

                            <div class="_value _small">

                                    <div class="targetGroup">
                                        <div class="targetInfo">
                                            <div class="gross">{{ grossToTarget(target.target) }}%</div>
                                            <div class="target">${{ priceConverter(target.target) }}</div>
                                        </div>
                                        <div class="targetBar">
                                            <div class="completionBar" :style="`width: ${ grossToTarget(target.target) }%`"></div>
                                        </div>
                                    </div>
                                    
                                    <div class="_subtleVal" v-if="grossToTarget(target.target) < 100">${{ priceConverter((target.target - report.gross).toFixed(0)) }} left to earn an extra ${{ priceConverter(target.bonus) }}</div>
                                    <div class="_subtleVal" v-else>Congrats! You smashed it and earned ${{ priceConverter(target.bonus) }}</div>
                                
                            </div>
                        </div>
                    </div>
                </ModalSimple>


                <div class="overStats">
                    <h2>Overall stats</h2>
                    <div class="_content">

                        <div class="statsGroup">
                            <div class="_title"><i class="fas fa-dollar-sign"></i><span>Gross income</span></div>
                            <div class="_value">${{ priceConverter(report.gross) }}</div>
                        </div>

                        <div class="statsGroup">
                            <div class="flexTitle">
                                <div class="_title"><i class="fas fa-dollar-sign"></i><span>Personal income</span></div>
                                <button class="default small reverse actionBtn" @click="showIncomeDetails"><i class="fas fa-question"></i></button>
                            </div>
                            <div class="_value">${{ priceConverter(report.total) }}</div>
                        </div>

                        <div class="statsGroup" v-if="report.monthlyTarget">
                            <div class="flexTitle">
                                <div class="_title"><i class="fas fa-dollar-sign"></i><span>Monthly bonus</span></div>
                                <button class="default small reverse actionBtn" @click="showBonusDetails = true;"><i class="fas fa-question"></i></button>
                            </div>
                            <div class="_value">${{ priceConverter(monthlyBonus) }}</div>
                        </div>

                    </div>
                </div>


                <div class="reportTitle">
                    <h2>Report</h2>
                    <form :action="`${apiURL}/reports/GeneratePDF`" target="_blank" method="POST">
                        <input type="text" name="report" :value="JSON.stringify(report)" hidden>
                        <button class="default small round reverse" type="submit">Download PDF</button>
                    </form>
                </div>

                <div class="reportItems">
                    <ReportItem :key="key" v-for="(item, key) of Object.keys(report.weeks)" :name="item" :bonus="report.bonusValue" :target="report.target" :item="report.weeks[item]"/>
                </div>

            </div>
        </div>

        
        
    </div>
</template>

<script>
import YearWeekSelector from '../../components/dispatch/YearWeekSelector.vue'
import MultiWeekSelector from '../../components/MultiWeekSelector.vue'
import PayRollSelector from '../../components/PayRollSelector.vue'
import ReportItem from '../../components/ReportItem.vue'
import UserSelector from '../../components/reports/UserSelector.vue'
import ReportSettings from '../../components/reports/ReportsSettings.vue'
import FullReport from '../../components/reports/FullReport.vue'

import moment from 'moment'
import { mapGetters } from 'vuex'

import GenerateReport from '../../components/reports/GenerateReport.vue'

    export default {
        components: {
            YearWeekSelector,
            MultiWeekSelector,
            PayRollSelector,
            ReportItem,
            UserSelector,
            ReportSettings,
            FullReport
        },
        data() {
            return {
                periods: [
                    { name: '2 weeks', value: 2 },
                    { name: '4 weeks', value: 4 },
                ],
                selectedPeriod: 0,
                report: null,
                userSalarySettings: null,
                incomeDetails: false,
                payRollDate: null,
                selectedUser: null,
                showReportSettings: false,
                showBonusDetails: false,
                generateFullReport: false
            }
        },
        computed: {
            ...mapGetters(['user', 'carriers']),
            apiURL() {
                return process.env.VUE_APP_API_URL;
            },
            reportType() {
                return this.sections[this.selectedSection].type;
            },
            monthlyBonus() {
                if(!this.report.monthlyTarget) return 0;

                let total = 0;
                for(const item of this.report.monthlyTarget) {
                    if(item.done) total += item.bonus;
                }
                return total;
            },
            reportBody() {
                return {
                    date: this.payRollDate,
                    period: this.periods[this.selectedPeriod].value
                }
            },
            carriersObj() {
                let items = {};
                for(const carrier of this.carriers) {
                    items[carrier._id] = carrier;
                }
                return items;
            }
        },
        mounted() {
            this.selectedUser = this.user._id;
        },
        methods: {
            openReportModal() {
                this.$showModalSimple(GenerateReport, 'Generate Full Report', {
                    ...this.reportBody
                }, (data) => {
                    
                });
            },
            showIncomeDetails() {
                this.incomeDetails = true;
            },
            hideIncomeDetails() {
                this.incomeDetails = false;
            },
            getPayRollReport() {

                
                if(!this.payRollDate || !this.selectedUser) return;

                this.report = null;
                this.userSalarySettings = null;

                this.ajax('getPayRollByDate', {
                    url: `/reports/GetPayRollByDate`,
                    method: 'POST',
                    data: {
                        date: this.payRollDate,
                        user: this.selectedUser,
                        period: this.periods[this.selectedPeriod].value
                    }
                }, (err, body) => {

                    if(err) {
                        alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }


                    if(body.report) {
                        this.report = body.report;
                        this.userSalarySettings = body.UserSalarySettings
                    } else {
                        throw new Error();
                    }

                });

            },
            grossToTarget(target) {
                return ((Number(this.report.monthlyGross) * 100) / Number(target)).toFixed(2);
            },
        },
        watch: {
            payRollDate() {
                this.getPayRollReport();
            },
            selectedUser() {
                this.getPayRollReport();
            }
        }
    }
</script>

<style lang="scss" scoped>

.userSalarySettingsGrid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 20px;
    align-items: start;
    align-content: start;
}

.settingsBlock {
    padding: 20px;
    background: $bgSecondary;
    border-radius: $borderRadius;
    display: grid;
    row-gap: 20px;
    box-shadow: $boxShadow;
    .title {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .settingsRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        border-bottom: 1px solid $borderColor;
        padding: 0 10px;
        &:last-child {
            border: 0;
        }
        &.header {
            font-weight: bold;
            text-transform: uppercase;
            font-size: 10px;
            background: $buttonColor2;
            height: 28px;
            border: 0;
            border-radius: 5px;
        }
    }

}

.reportTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.showSettings {
    display: flex;
    align-items: flex-end;
    column-gap: 20px;
    .input {
        width: auto;
    }
}

.periods {
    display: flex;
    align-items: center;
    button {
        margin-right: 10px;
    }
}

.actionBar {
    display: grid;
    column-gap: 20px;
    grid-template-columns: auto auto auto auto;
}

.reportItems {
    display: grid;
    row-gap: 20px;
}


.overStats {
    display: grid;
    row-gap: 20px;
    ._content {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        column-gap: 20px;
    }
}

.reportGroup {
    display: grid;
    row-gap: 30px;
}

.incomeDetails {
    display: grid;
    row-gap: 30px;
    .totalRow {
        text-align: right;
        width: 100%;
        font-size: 20px;
    }
}

.incomeListDetails {
    border-radius: $borderRadius;
    overflow: hidden;
    border: 1px solid $borderColor;
    .row {
        display: grid;
        grid-template-columns: minmax(0, 1fr) auto;
        column-gap: 20px;
        height: 50px;
        align-items: center;
        background: $bg;
        padding: 0 15px;
        border-bottom: 1px solid $borderColor;
        &:nth-child(2n) {
            background: $bgSecondary;
        }
        &:last-child {
            border: 0;
        }
        .value {
            text-align: right;
            font-weight: bold;
        }
    }
}


.bonusTargets {
    display: grid;
    row-gap: 20px;
    .statsGroup {
        background: $bg;
        border: 1px solid $borderColor;
    }   
}
</style>