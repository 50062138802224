<template>
    <div>
        <div class="multiWeekSelection">
            
            <button class="_btn" @click="prevWeek"><i class="fas fa-caret-left"></i></button>
            <div :key="key" v-for="(week, key) of weeks" class="weekBtn" @click="addRemoveYearWeek(week)"  :class="{ 'active' : isSelected(week) }">
                <button class="weekSelection" :class="{ 'active' : isSelected(week) }">
                    <span v-if="isSelected(week)"><i class="fas fa-check"></i></span>
                </button>
                <div class="matchedWeek" v-if="match && match.includes(week.yearWeek)"><i class="fas fa-check"></i></div>
                <div class="weenNum">W{{ week.week }}</div>
                <div>{{ week.stringDate }}</div>
            </div>
            <button class="_btn" @click="nextWeek"><i class="fas fa-caret-right"></i></button>

        </div>

    </div>
</template>

<script>
import moment from 'moment';

    export default {
        props: ['modelValue', 'match'],
        data() {
            return {
                start: moment().startOf('isoWeek'),
                weeksCount: 4,
                step: 0,
                selection: []
            }
        },
        methods: {
            nextWeek() {
                this.step++;
                this.start.add(1, 'isoWeeks');
            },
            prevWeek() {
                this.step--;
                this.start.subtract(1, 'isoWeeks');
            },
            isSelected(item) {
                return this.selection.includes(item.yearWeek);
            },
            addRemoveYearWeek(item) {
                if(!this.selection.includes(item.yearWeek)) {
                    this.selection.push(item.yearWeek);
                } else {
                   this.selection.splice(this.selection.indexOf(item.yearWeek), 1); 
                }
            }
        },
        mounted() {
            this.$emit('update:modelValue', this.selection);
        },
        computed: {
            weeks() {
                let weeks = [];
                let step = this.step;

                let weekStartFrom = this.start.clone();
                weekStartFrom.add(2, 'weeks');

                for(let i = 1; i <= this.weeksCount; i++) {
                    let date = weekStartFrom.subtract(1, 'weeks')
                    weeks.push({
                        week: date.isoWeek(),
                        yearWeek: date.year() + "W" + date.isoWeek(),
                        stringDate: date.format('D MMM, YYYY'),
                        stamp: date.unix()
                    });
                }

                return weeks.reverse();
            },
            sortedSelection() {
                return this.selection.sort();
            }
        },
        watch: {
            selection: {
                deep: true,
                handler() {
                    this.$emit('update:modelValue', this.selection);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.multiWeekSelection {
    display: grid;
    grid-template-columns: 60px repeat(4, minmax(0, 1fr)) 60px;
    align-content: stretch;
    text-align: center;
    height: 100px;
    background: $bgSecondary;
    box-shadow: $boxShadow;
    border-radius: $borderRadius;
    ._btn {
        height: 100%;
        background: transparent;
        border: 0;
    }
}

.weekBtn {
    height: 100%;
    display: grid;
    row-gap: 5px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    border-right: 1px solid $borderColor;
    cursor: pointer;
    user-select: none;
    transition: ease 0.5s;
    position: relative;
    .matchedWeek {
        position: absolute;
        top: 7px;
        right: 7px;
        font-size: 12px;
        color: $success;
    }
    &:first-of-type {
        border-left: 1px solid $borderColor;
    }
    &:hover {
        background: $buttonColor2;
    }
}

.weenNum {
    font-weight: bold;
}

.weekSelection {
    width: 34px;
    height: 34px;
    font-size: 12px;
    border: 2px solid $borderColor;
    border-radius: 50%;
    text-align: center;
    background: transparent;
    padding: 0;
    &.active {
        color: $buttonColor;
        background: $buttonColor2;
        border: 2px solid $buttonColor;
    }
}
</style>